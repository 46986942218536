

// Components
import { JobBankRouteNames } from '@/router/route-names'
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {

  },
  setup () {
    const route = useRoute()
    const currentRoute = computed(() => {
      return route.name
    })
    return {
      JobBankRouteNames,
      currentRoute
    }
  }
})
